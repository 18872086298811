const config = {
  routes: {
    FORM_TYPE: '/',
    FORM: '/:form',
    QUESTIONS: '/:form/:formId',
    LOGIN_CALLBACK: '/login/callback',
    DEPLOYMENT_MANAGER: '/forms/:formId/deployment-manager',
    NOT_EXIST: '*',
  },
  questionEngine: {
    applicationName: 'question-engine-admin-ui',
    script: process.env.REACT_APP_QE_SCRIPT as string,
    environment: process.env.REACT_APP_QE_ENVIRONMENT as string,
  },
  PAGE_SIZE_ARRAY: [10, 25, 50],
  API_URL: process.env.REACT_APP_API_URL || '',
  API_AUTH_URL: process.env.REACT_APP_API_AUTH_URL || '',
  GUIDE_IMAGES_URL: process.env.REACT_APP_GUIDE_IMAGES_URL || '',
  CREDENTIALS_URL: process.env.REACT_APP_CREDENTIALS_URL || '',
} as const;

const authConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  location: window.location,
  provider: process.env.REACT_APP_PROVIDER || '',
  redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin + '/login/callback',
  scopes: process.env.REACT_APP_SCOPES ? process.env.REACT_APP_SCOPES.split(' ') : [],
  tokenEndpoint: config.API_URL + '/auth/token',
  amsGraphqlEndpoint: config.CREDENTIALS_URL + '/ams/graphql',
  extraParams: {
    adaptor: process.env.REACT_APP_ADAPTOR || process.env.REACT_APP_API_URL || '',
  },
} as const;

export { config, authConfig };
